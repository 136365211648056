
nav svg {
    stroke: #200E32;
}

.nombre_usuario {
    font-size: 1.8em;
    padding-top: 10px;
    text-transform: capitalize;
    margin-left: 25px;
}

    .nombre_usuario span {
        color: #FF9356;
        font-style: italic;
    }

.navbar.private {
    position: fixed !important;
    display: block !important;
    background-color: white;
    padding-right: 2px;
}

    /*.navbar.private .logo_usuario {
        width: 100px;
        height: 100px;
        background-size: cover;
    }*/



    .navbar.private ul {
        margin: 0;
        padding: 30px 0;
        width: 100%;
    }

        .navbar.private ul li.nav-item.last {
            margin-top: 20px;
        }

        .navbar.private ul li.nav-item {
            list-style-type: none;
            padding-left: 0;
        }

            .navbar.private ul li.nav-item a {
                padding-left: 5px;
                padding-right: 0;
            }

                .navbar.private ul li.nav-item a:hover {
                    cursor: pointer;
                    color: #FF9356 !important;
                }

                    .navbar.private ul li.nav-item a:hover svg {
                        stroke: #FF9356 !important;
                    }

                .navbar.private ul li.nav-item a i {
                    margin-right: 5px;
                }


.container-fluid {
    padding-top: 50px;
}

.cabecera {
    display: none;
}

@media (min-width: 1200px) {
    body {
        padding-left: 220px;
    }

    .container-fluid {
        padding-top: 90px;
    }

    .cabecera {
        height: 70px;
        display: flex;
        background-color: #FFF;
        position: fixed;
        margin-bottom: 10px;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1024;
        padding-left: 220px;
        padding-top: 10px;
        padding-right: 30px;
    }

        .cabecera .name {
        }

        .cabecera .nav {
            flex-grow: 1;
        }


    .navbar.private {
        width: 220px !important;
        padding-left: 10px;
    }

        .navbar.private .container {
            display: block !important;
        }

        .navbar.private .logo_usuario {
            background-image: url('/assets/img/cara.png');
            margin-top: 50px;
        }

        .navbar.private .nombre_usuario {
            margin-bottom: 20px;
        }
}



.wizardBMI .modal-body, .wizard {
    overflow: hidden;
}

.modal-body form {
    margin: 5px 20px;
}

.error-text {
    margin-top: .25rem;
    font-size: 110%;
    color: #dc3545;
}

.modal-header {
    background-color: #1b6ec2;
}

    .modal-header .modal-title, .modal-header button {
        color: white;
    }

.logo {
    width: 100%;
    max-width: 250px;
    display: block;
}

.uploader section {
    padding: 0 !important;
}

.uploader p {
    display: block;
    height: 70px;
    border: dotted 2px black;
    background-color: #ffe287;
    text-align: center;
    line-height: 70px;
    margin-bottom: 0px;
}

div.card-container {
    padding-left: 10px;
    padding-right: 10px;
}

/** INICIO CARDS DE LA HOME */

a.import-source:hover {
    text-decoration: none;
}

.card.home {
    height: 115px;
}


    .card.home.import {
        border: 2px dashed #1C9038;
        cursor: pointer;
    }

        .card.home.import > .card-body > .card-img {
            margin-right: 0px;
        }

    .card.home > .card-body {
        padding: 10px;
        display: flex;
        border: none;
    }

        .card.home > .card-body > .card-img {
            width: 76px;
            height: 76px;
            margin-right: 15px;
            margin-top: 10px;
        }

        .card.home > .card-body > div {
        }

            .card.home > .card-body > div > .card-subtitle {
                font-family: 'GT Walsheim Pro';
                font-style: normal;
                font-weight: 600;
                font-size: 1.2em;
                line-height: 50px;
                color: #0B1C37;
            }

            .card.home > .card-body > div > .card-title {
                margin-top: 5px;
                font-family: 'GT Walsheim Pro';
                font-style: normal;
                font-weight: normal;
                font-size: 1em;
                line-height: 15px;
                color: #000000;
                opacity: 0.5;
            }
/*** FIN CARDS DE LA HOME ***/

/*** CARDS ***/
.card.data-source {
    max-width: 180px;
    margin-bottom: 35px;
}

    .card.data-source button {
        margin-top: 2px !important;
        font-size: 14px;
    }

        .card.data-source button svg {
            margin-right: 10px;
        }

/*** INICIO GRAFICOS ***/
.recharts-cartesian-axis-tick-value {
    color: #AFAFAF;
}

.charts-container {
    background-color: #FFF;
}

    .charts-container h3 {
        padding: 10px 20px;
    }

.recharts-legend-item {
    padding: 5px;
    background-color: #fcfbff;
}
/*** FIN GRAFICOS ***/

/*** QUOTE HOME ***/
div.home-quote {
    min-height: 100%;
    height: 100%;
    background-image: url(/assets/img/fondo-valuation.png);
    background-position: top left;
    background-size: cover;
    color: white;
    padding: 45px 30px 20px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

    div.home-quote h2 {
        font-size: 2em;
        margin-bottom: 20px;
    }

        div.home-quote h2.valuation {
            font-size: 3em;
        }

    div .home-quote p {
        line-height: 1.8em;
    }

    div.home-quote button {
        background-color: #FEB92F;
        border-radius: 0px;
        color: #333;
        font-weight: bold;
        padding: 15px 40px;
        margin-top: 20px;
    }

    div.home-quote > div {
        width: 100%;
    }

select.form-control-sm {
    border: none;
    height: unset;
    padding: 0;
    border-bottom: 1px solid #AFAFAF;
    width: 100px;
}

/*** TABLAS DE DATOS ***/
div.data-table {
    background-color: #FFF;
    padding: 10px;
}

    div.data-table > h3 {
        line-height: 32px;
    }

    div.data-table select.form-control {
        border: none;
        height: unset;
        padding: 0;
        border-bottom: 1px solid #AFAFAF;
    }

    div.data-table h3 {
        font-size: 21px;
    }

    div.data-table .table.table {
        margin-top: 15px;
    }

    div.data-table table.table th {
        color: #AFAFAF;
        font-size: 0.85em;
        padding-top: 5px;
        padding-bottom: 5px;
        font-weight: normal;
    }

    div.data-table table.table td {
        font-size: 0.9em;
    }

        div.data-table table.table td button.btn {
            padding: 0px;
        }

        div.data-table table.table td.song a {
            text-decoration: none;
            text-transform: capitalize;
            color: unset;
        }

            div.data-table table.table td.song a::before {
                content: url("/assets/img/icons/headphones.png");
                margin-right: 8px;
                margin-top: 2px;
                float: left;
            }

        div.data-table table.table td.source {
            text-decoration: none;
            text-transform: capitalize;
            color: unset;
        }

ul.importResult {
    list-style: none;
    padding: 0;
    font-size: 0.8em;
    text-align: left;
}

body.dark {
    background-color: #121212 !important;
    color: #efefef;
}

    body.dark img {
        filter: brightness(.8) contrast(1.2);
    }

        body.dark img.logo {
            background-color: #fff;
        }

    body.dark .navbar-brand img.logo {
        background-color: transparent !important;
    }

    body.dark .navbar.private {
        background-color: #222 !important;
    }

    body.dark .cabecera {
        background-color: #222 !important;
    }

    body.dark select {
        background-color: #121212;
        color: #efefef !important;
    }

    body.dark .cabecera select {
        background-color: #222 !important;
        color: #efefef !important;
    }


    body.dark .nav-item .text-dark {
        color: #efefef !important;
    }

    body.dark nav svg {
        stroke: #efefef;
    }

    body.dark .data-source .text-center {
        background-color: #222 !important;
    }

    body.dark div.data-table {
        background-color: #121212 !important;
    }

    body.dark .table {
        color: #efefef !important;
    }

    body.dark .table-hover tbody tr:hover {
        color: #ddd;
        background-color: #222;
    }

    body.dark .table-striped tbody tr:nth-of-type(odd) {
        color: #ddd;
        background-color: #222;
    }

    body.dark .form-control {
        background-color: #121212 !important;
        color: #3bc85d !important;
        color-scheme: dark;
    }

    body.dark div.form-group.border-label label {
        background-color: #121212 !important;
    }

    body.dark .home.card {
        background-color: #222 !important;
    }

    body.dark .charts-container, body.dark .recharts-legend-item {
        background-color: #121212;
    }

        body.dark .charts-container text {
            fill: #efefef;
        }

        body.dark .charts-container h3, body.dark .data-table h3 {
            color: #efefef;
        }

    body.dark .recharts-tooltip-wrapper, body.dark .recharts-default-tooltip {
        background-color: #222;
    }

    body.dark .recharts-tooltip-label, body.dark .uploader p {
        color: #121212;
    }

    body.dark .card.home.import {
        border: 2px dashed #1C9038 !important;
    }

    body.dark .card.home > .card-body > div > .card-subtitle {
        color: #efefef !important;
    }

    body.dark .card.home > .card-body > div > .card-title {
        color: #efefef !important;
        opacity: 0.9 !important;
    }

    body.dark .modal-content {
        background-color: #121212 !important;
        color: #efefef !important;
        border: solid 1px #efefef;
    }

    body.dark .pagination .page-item a {
        color: #121212;
        background-color: #1C9038;
    }

        body.dark .pagination .page-item a:hover {
            background-color: #efefef;
        }
