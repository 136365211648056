/*
@media (min-width:768px) {
    .container {
        width: 503px
    }
}

@media (min-width:992px) {
    .container {
        width: 723px
    }
}

@media (min-width:1200px) {
    .container {
        width: 923px
    }
}

@media (min-width:1432px) {
    .container {
        width: 1170px
    }
}
*/

body {
    padding-top: 70px;
    min-height: 200vh
}

.navbar-fixed-left, .navbar-fixed-right {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1030
}

@media (min-width:1200px) {
    .navbar-fixed-left, .navbar-fixed-right {
        width: 232px;
        height: 100vh;
        border-radius: 0
    }

        .navbar-fixed-left .container, .navbar-fixed-right .container {
            padding-right: 0;
            padding-left: 0;
            width: auto
        }

        .navbar-fixed-left .navbar-header, .navbar-fixed-right .navbar-header {
            padding-left: 15px;
            padding-right: 15px
        }

        .navbar-fixed-left .navbar-collapse, .navbar-fixed-right .navbar-collapse {
            padding-right: 0;
            padding-left: 0;
            max-height: none
        }

            .navbar-fixed-left .navbar-collapse .navbar-nav, .navbar-fixed-right .navbar-collapse .navbar-nav {
                float: none !important
            }

                .navbar-fixed-left .navbar-collapse .navbar-nav > li, .navbar-fixed-right .navbar-collapse .navbar-nav > li {
                    width: 100%
                }

                    .navbar-fixed-left .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu, .navbar-fixed-right .navbar-collapse .navbar-nav > li.dropdown .dropdown-menu {
                        top: 0
                    }

                .navbar-fixed-left .navbar-collapse .navbar-nav.navbar-right, .navbar-fixed-right .navbar-collapse .navbar-nav.navbar-right {
                    margin-right: 0
                }
}

@media (min-width:1200px) {
    body {
        padding-top: 0;
        padding-left: 232px
    }
    .navbar.private {
        padding-left: 0px;
        padding-right: 0px;
    }
    .navbar-fixed-left {
        right: auto !important;
        left: 0 !important;
        border-width: 0 1px 0 0 !important
    }

        .navbar-fixed-left .dropdown .dropdown-menu {
            left: 100%;
            right: auto;
            border-radius: 0 3px 3px 0
        }

        .navbar-fixed-left .dropdown .dropdown-toggle .caret {
            border-top: 4px solid transparent;
            border-left: 4px solid;
            border-bottom: 4px solid transparent;
            border-right: none
        }
}
