
.navbar.public {
    margin-bottom: 20px;
    position: fixed;
    top: 0px;    
    padding-bottom: 15px;
    background-color: #FFF;
}

section.public-content {
    margin-top: 120px;
}

div.form-content {
    display: flex;
    flex-direction: column;
    justify-content: center
}

    div.form-content img {
        width: 260px;
    }

div.jumbotron {
    font-family: 'GT Walsheim Pro';
    padding: 1em 1em;
    align-content: center;
    text-align: center;
    color: #FFF;
    background-image: url("/assets/img/fondo-jumbotron.png");
    background-attachment: unset;
    background-position: bottom;
    background-size: cover;
    margin-bottom: 0px;
}

    div.jumbotron h1 {
        font-size: 1.5em;
        margin-bottom: 2em;
    }

    div.jumbotron p.lead {
        font-size: 1.65em;
    }

    div.jumbotron img {
        max-width: 70%;
        margin: auto;
    }


@media (min-width: 1200px) {
    div.jumbotron p.lead {
        font-size: 2em;
    }
}
